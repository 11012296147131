@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: #e7a55e;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes wave {
  0% {
    transform: translateX(0);
    transform: translateY(0);
  }
  50% {
    transform: translateX(20px); 
    transform: translateY(10px);
  }
  100% {
    transform: translateX(0);
    transform: translateY(0);
  }
}

.wave1 {
  animation: wave 8s ease-in-out infinite;
}

.wave2 {
  animation: wave 8s ease-in-out infinite;
  animation-delay: 0.5s; 
}

.wave3 {
  animation: wave 6s ease-in-out infinite;
  animation-delay: 1s;
}

.wave4 {
  animation: wave 6s ease-in-out infinite;
  animation-delay: 1.5s;
}

.wave5 {
  animation: wave 8s ease-in-out infinite;
  animation-delay: 2s;
}

.wave6 {
  animation: wave 8s ease-in-out infinite;
  animation-delay: 1s;
}

/* FONTS & TEXT */

.raleway-regular {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  letter-spacing: .2rem;
}

.raleway-medium {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  letter-spacing: .2rem;
}

.raleway-heavy {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
  letter-spacing: .5rem;
}

.jersey-25-charted-regular {
  font-family: "Jersey 25 Charted", sans-serif;
  font-weight: 400;
  font-style: normal;
}


/* ANIMATOR */

.animator {
  animation: fade-in ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fade-in {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}